import React, { useState, useEffect } from 'react';
import { helpers, styled } from 'react-free-style';
import classnames from 'classnames';
import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import { Multiselect } from '../react-multi-select/react-multi-select';
import { castingSearchIndexes } from '../../support/items/filter-types';

const CastingsSearch = props => {
  const { styles, className, castingsName } = props;
  const handleCastingsNameChange = e => {
    props.onCastingsNameChange(e.target.value);
  };
  const clearInput = () => {
    props.onCastingsNameChange('');
  };
  const [disabledOptions, setDisableOptions] = useState([]);

  useEffect(() => {
    if (props.selectedIndexes.includes('All')) {
      let filterArray = [...castingSearchIndexes];
      const index = filterArray.indexOf('All');
      filterArray.splice(index, 1);
      setDisableOptions(filterArray);
    } else {
      setDisableOptions([]);
    }
  }, [props.selectedIndexes]);

  return (
    <div className="mx-0 mt-0 mb-0 row">
      <form className={classnames(styles.container, className)} onSubmit={e => e.preventDefault()}>
        <input
          type="text"
          className={styles.input}
          placeholder="Search Casting Role Names, Casting Notes, Casting Descriptions, and Project Names"
          value={castingsName}
          onChange={handleCastingsNameChange}
        />
        {castingsName && (
          <div className={styles.clear} onClick={clearInput}>
            <i className={styles.clearIcon} />
          </div>
        )}
      </form>
      <div className="pl-0 pr-2 col w-100">
        <Multiselect
          title="All"
          options={castingSearchIndexes}
          selectedOptions={props.selectedIndexes}
          onSelect={option => {
            props.handleCastingIndexChange(option);
          }}
          disabledOptions={disabledOptions}
        />
      </div>
    </div>
  );
};

const withStyles = styled({
  container: {
    position: 'relative',
    'flex-grow': 3,
    'margin-right': '10px',
  },
  input: helpers.merge(elements.input, {
    marginRight: 38,
    paddingTop: '0.9em',
    paddingBottom: '0.3em',
  }),
  clear: helpers.merge(elements.actionable, {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 13,
    lineHeight: 0,
  }),
  clearIcon: helpers.merge(icons.crossBlack, {
    width: icons.crossBlack.width * 0.8,
    height: icons.crossBlack.height * 0.8,
  }),
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(CastingsSearch);
