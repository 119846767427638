export const getSearchableCastingIndexes = filter => {
  let searchableAttributes = [];
  if (filter.castingSearchIndexes && filter.castingSearchIndexes.length === 1 && filter.castingsName !== undefined) {
    filter.castingSearchIndexes.forEach(index => {
      if (index === 'Casting Role Names') {
        searchableAttributes.push('role');
      }
      if (index === 'Casting Notes') {
        searchableAttributes.push('notes');
      }
      if (index === 'Casting Descriptions') {
        searchableAttributes.push('description');
      }
      if (index === 'Project Names') {
        searchableAttributes.push('projectName');
      }
    });
  }
  return searchableAttributes;
};

export const getSearchableProjectsIndexes = filter => {
  let searchableAttributes = [];

  if (filter.projectSearchIndexes && filter.projectSearchIndexes.length === 1 && filter.name !== undefined) {
    filter.projectSearchIndexes.forEach(index => {
      if (index === 'Project Names') {
        searchableAttributes.push('name');
      }
      if (index === 'Log Lines') {
        searchableAttributes.push('log_line');
      }
    });
  }
  return searchableAttributes;
};
