/* eslint-disable react/no-direct-mutation-state */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { notify } from 'react-notify-toast';
import hash from 'object-hash';
import { debounce } from 'lodash';
import { recordEvent, castingSearchCastings, createCastingGrid } from '@united-talent-agency/julius-frontend-store';

import { getGroups, groupRoles } from '../../../../../api/groups';
import { CastingsBadgeBuilder } from '../../../../../components/filter-model/filter-badge-builder';
import CollapsedFilters from '../../../../../components/collapse-filters/collapse-filters';
import ListManager from '../../../../../components/list-manager/list-manager';
import GridManager from '../../../../../components/grid-manager/grid-manager';
import PrintManager from '../../../../../components/print-manager/print-manager';
import CastingFilterForm from '../../../../../components/casting-filter-form/casting-filter-form';
import CastingsList from './current-castings-list';
import CastingsSearch from '../../../../../components/search/castings-search';
import { downloadFile } from '../../../../../support/download';
import CountLabel from '../../../../../components/count-label/count-label';
import { castingSearchIndexes, castingTypes } from '../../../../../support/items/filter-types';
import { Multiselect } from '../../../../../components/multiselect/Multiselect';

const PAGE_SIZE = 100;
const MAX_RESULTS = 2000;
class SearchCastings extends Component {
  constructor(props) {
    super(props);
    const { grid, filterModel } = this.props;

    const filter = grid ? grid.castingFilter : filterModel;
    this.state = {
      castingsName: filter && (filter.name || filter.castingsName),
      filterModel: props.filterModel,
      areFiltersCollapsed: false,
      isGridManagerCollapsed: true,
      isListManagerCollapsed: true,
      isPrintManagerCollapsed: true,
      isPrinting: false,
      projectCastings: [],
      selectAll: false,
      selection: {},
      lastSearchHash: '',
      sortColumn: 'Project',
      sortDirection: 1,
      gettingData: false,
      castingSearchIndexes: castingSearchIndexes,
    };
    this.state.filterModel.limit = PAGE_SIZE;
    this.createGrid = this.createGrid.bind(this);
    this.show = notify.createShowQueue();
    this.filterCastings = this.filterCastings.bind(this);
    this.handleCastingsNameChange = this.handleCastingsNameChange.bind(this);
    this.castingSearchBoxDebounced = debounce(this.filterCastings, 500);
    this.searchCompany = this.searchCompany.bind(this);
    this.getSelectedProjects = this.getSelectedProjects.bind(this);
  }
  handleCastingsNameChange(castingsName) {
    const filterModel = this.state.filterModel;
    filterModel['castingsName'] = castingsName;
    this.setState({
      castingsName,
      filterModel,
    });
    this.castingSearchBoxDebounced(filterModel);
  }

  componentDidMount() {
    const { grid, filterModel } = this.props;
    const filter = grid ? grid.castingFilter : filterModel;
    this.filterCastings(filter);
  }

  hashFilter(filter) {
    const exclusions = function(key) {
      if (key === 'limit' || key === 'skip' || key === 'sort') {
        return true;
      }
      return false;
    };
    return hash(filter, { excludeKeys: exclusions });
  }

  filterCastings(filter, append = false) {
    const { dispatch } = this.props;
    const { lastSearchHash, gettingData, sortColumn, sortDirection, castings } = this.state;
    if (gettingData) {
      return;
    } else {
      this.setState({ gettingData: true });
    }

    //reset selection if search changes - ignore sorting/pagination
    const searchHash = this.hashFilter(filter);
    if (lastSearchHash !== searchHash) {
      this.setState({
        selectAll: false,
        selection: {},
        isPrintManagerCollapsed: true,
        isListManagerCollapsed: true,
        isGridManagerCollapsed: true,
        lastSearchHash: searchHash,
      });
    }

    if (!filter.castingsName) {
      filter.castingsName = '';
    }

    //first-time search default
    if (!filter.sort) {
      filter.sort = { [sortColumn]: sortDirection };
    }

    if (append) {
      filter.skip = castings.length;
    } else {
      delete filter.skip;
    }

    dispatch(castingSearchCastings(filter)).then(searchResult => {
      const filterBadges = this.buildCastingBadgesFromFilter(filter);
      const existingCastings = append ? Object.assign([], castings) : [];
      const newCastings = existingCastings.concat(searchResult.body ? searchResult.body.data : []);
      this.setState({
        filterBadges: filterBadges.badges,
        castings: newCastings,
        gettingData: false,
        resultCount: searchResult.body ? searchResult.body.total : 0,
      });
      return searchResult;
    });
  }

  buildCastingBadgesFromFilter(filterModel) {
    const filterBadges = new CastingsBadgeBuilder(filterModel);
    filterBadges.buildCastings();
    filterBadges.badges.forEach(badge => {
      badge.onRemoveBadge = () => this.onRemoveBadge(badge, filterModel);
    });
    return filterBadges;
  }

  // eslint-disable-next-line no-undef
  onRemoveBadge = async badge => {
    const { dispatch, user } = this.props;
    const filterModel = this.state.filterModel;
    switch (badge.property) {
      case 'ageRange':
        delete filterModel['ageMin'];
        delete filterModel['ageMax'];
        delete filterModel['ageRange'];
        break;
      case 'ethnicities':
        filterModel.ethnicities = filterModel.ethnicities.filter(p => {
          return p !== badge.name;
        });
        break;
      case 'statuses':
        filterModel.statuses = filterModel.statuses.filter(s => {
          return s !== badge.name;
        });
        break;
      case 'profiles':
        filterModel.profiles.include = filterModel.profiles.include.filter(p => {
          return p.name !== badge.name;
        });
        break;
      case 'networks':
        filterModel.networks.include = filterModel.networks.include.filter(p => {
          return p.name !== badge.name;
        });
        break;
      case 'studios':
        filterModel.studios.include = filterModel.studios.include.filter(p => {
          return p.name !== badge.name;
        });
        break;
      case 'productionCompanies':
        filterModel.productionCompanies.include = filterModel.productionCompanies.include.filter(p => {
          return p.name !== badge.name;
        });
        break;
      case 'distribution':
        filterModel.distribution.include = filterModel.distribution.include.filter(d => {
          return d !== badge.name;
        });
        break;
      default:
        delete filterModel[badge.property];
    }

    dispatch(
      recordEvent(
        'projects.search.remove-badge',
        'projects-web',
        'info',
        '1',
        [{ data: `${badge.name}_${badge.label}` }],
        user._id
      )
    );
    await this.filterCastings(filterModel);
  };

  render() {
    const props = this.props;
    const state = this.state;
    const { desk, dispatch, styles, user, grid, canEdit } = props;

    const {
      areFiltersCollapsed,
      isPrintManagerCollapsed,
      isListManagerCollapsed,
      isGridManagerCollapsed,
      selectAll,
      selection,
      sortColumn,
      sortDirection,
      resultCount,
      filterModel = {},
      castings,
    } = this.state;
    const filter = grid ? grid.castingFilter : filterModel;
    if (!desk?.current) {
      return (
        <div className="my-5 text-center">
          <h5>No Desk Assigned</h5>
        </div>
      );
    }
    const columnWidths = {
      tableColumn: areFiltersCollapsed ? styles.tableColumnCollapsed : styles.tableColumn,
      filterColumn: areFiltersCollapsed ? styles.filterColumnCollapsed : styles.filterColumn,
    };

    const selectedTypes = castingTypes.filter(type => {
      return (filter.projectTypes || []).some(fType => fType === type);
    });

    const selectedIndexes = castingSearchIndexes.filter(item => {
      return (filter.castingSearchIndexes || []).some(fType => fType === item);
    });

    const disableSelectBased =
      !castings ||
      castings.length === 0 ||
      (selectAll && Object.keys(selection).length === castings.length) ||
      (!selectAll && Object.keys(selection).length === 0);
    return (
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.content}>
            <div>
              <div className="mx-0 mt-0 mb-0 row">
                <div className={styles.typeSelectionColumn}>
                  <div className="pl-0 pr-2 col w-100">
                    <Multiselect
                      title="Type"
                      options={castingTypes}
                      selectedOptions={selectedTypes}
                      onSelect={option => {
                        this.onChangeProjectTypes(option);
                      }}
                    />
                  </div>
                </div>

                <div className={styles.searchInputColumn}>
                  <CastingsSearch
                    castingsName={state.castingsName}
                    onCastingsNameChange={this.handleCastingsNameChange}
                    filterModel={filter}
                    handleCastingIndexChange={this.handleCastingIndexChange}
                    selectedIndexes={selectedIndexes}
                  />
                  <CountLabel label="result" count={resultCount} className={styles.countLabel} />
                </div>
              </div>

              <div className={styles.container}>
                <div className={columnWidths.filterColumn}>
                  {desk && !areFiltersCollapsed && (
                    <CastingFilterForm
                      collapseEvent={() => this.collapseEvent('filter')}
                      deskId={desk.current._id}
                      filterModel={filter}
                      dispatch={dispatch}
                      filterCastings={this.filterCastings}
                      updateFilter={this.filterCastings}
                      styles={props.styles}
                      searchCompany={this.searchCompany}
                      recordEvent={value => {
                        dispatch(
                          recordEvent(
                            'projects.castings.search.filter-event',
                            'projects-web',
                            'info',
                            '1',
                            [value],
                            user._id
                          )
                        );
                      }}
                    />
                  )}
                  {areFiltersCollapsed && <CollapsedFilters expandEvent={() => this.expandEvent('filter')} />}
                </div>

                <div className={columnWidths.tableColumn}>
                  <CastingsList
                    resetFilters={this.resetFilters}
                    select={true}
                    filterModel={filter}
                    filterBadges={state.filterBadges}
                    castings={castings}
                    buttons={[
                      {
                        name: 'Print',
                        onClick: () => this.expandEvent('print'),
                        icon: state.isPrinting ? 'circle-o-notch fa-spin' : 'print',
                        hidden: !isPrintManagerCollapsed,
                        disabled: disableSelectBased,
                      },
                      {
                        name: 'Add to List',
                        onClick: () => this.expandEvent('list'),
                        icon: 'plus',
                        hidden: !isListManagerCollapsed,
                        disabled: disableSelectBased,
                      },
                      {
                        name: 'Save as Grid',
                        onClick: () => this.expandEvent('grid'),
                        icon: 'save',
                        hidden: !isGridManagerCollapsed,
                      },
                    ]}
                    onSelectedChanged={this.onSelectedChanged}
                    onSelectAllChanged={this.onSelectAllChanged}
                    selectAll={selectAll}
                    selection={selection}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSortChanged={(column, direction) => {
                      this.setState({ sortColumn: column, sortDirection: direction });
                      filterModel.sort = { [column]: direction };
                      this.filterCastings(filterModel);
                    }}
                    maxCount={MAX_RESULTS}
                    resultCount={resultCount}
                    onFetchMore={() => {
                      this.filterCastings(filter, true);
                    }}
                  />
                </div>
                {!isListManagerCollapsed && (
                  <div className={styles.listColumn}>
                    <ListManager
                      desk={desk.current}
                      user={user}
                      dispatch={dispatch}
                      projectsFunc={this.getSelectedProjects}
                      onCancel={() => {
                        this.collapseEvent('list');
                      }}
                      onCreated={list => {
                        this.collapseEvent('list');
                        if (list.corruptProject) {
                          this.show(`New List Created. Projects with errors were not added to list.`, 'custom', 3000, {
                            background: '#FF0000',
                            text: '#FFFFFF',
                          });
                          setTimeout(() => {
                            window.open(`/list/${list._id}`, list._id);
                          }, 3000);
                        } else {
                          this.show(`${list.name} created`, 'custom', 1000, {
                            background: '#000000',
                            text: '#FFFFFF',
                          });
                          window.open(`/list/${list._id}`, list._id);
                        }
                      }}
                      onUpdated={() => {
                        this.collapseEvent('list');
                        this.show(`List updated`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
                      }}
                    />
                  </div>
                )}
                {!isPrintManagerCollapsed && (
                  <div className={styles.listColumn}>
                    <PrintManager
                      grid={grid}
                      canEdit={canEdit}
                      savedOptions={grid && grid.printOptions}
                      isCastingResults={true}
                      updateGrid={this.props.updateGrid}
                      collapseEvent={() => this.collapseEvent('print')}
                      print={this.print}
                      userInTalent={user && user.personId && user.personId.department === 'Talent'}
                    />
                  </div>
                )}
                {!isGridManagerCollapsed && (
                  <div className={styles.listColumn}>
                    <GridManager
                      collapseEvent={() => this.collapseEvent('grid')}
                      createGrid={this.createGrid}
                      printOptions={filterModel.printOptions}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // eslint-disable-next-line no-undef
  resetFilters = async () => {
    const filterModel = {};
    filterModel.type = this.state.filterModel.type;
    filterModel.active = true;
    filterModel.castingSearchIndexes = castingSearchIndexes;
    this.setState({ filterModel });
    await this.filterCastings(filterModel);
    this.setState({ castingsName: '' });
  };

  // eslint-disable-next-line no-undef
  onChangeProjectTypes = type => {
    const { grid } = this.props;
    const { filterModel = {} } = this.state;
    const filter = grid ? grid.castingFilter : filterModel;
    const previousTypes = filter.projectTypes || [];
    const newTypes =
      previousTypes.indexOf(type) > -1 ? previousTypes.filter(t => t !== type) : previousTypes.concat(type);
    Object.keys(filter).forEach(key => {
      if (key !== 'castingSearchIndexes') delete filter[key];
    });
    filter.projectTypes = newTypes;
    filter.active = true;
    if (!grid) {
      this.setState({ filterModel: filter });
    }
    return this.filterCastings(filterModel);
  };
  // eslint-disable-next-line no-undef
  handleCastingIndexChange = currentIndex => {
    const { grid } = this.props;
    const { filterModel = {} } = this.state;
    const filter = grid ? grid.castingFilter : filterModel;
    const previousIndexes = filter.castingSearchIndexes || [];

    let newIndexes;

    if (currentIndex === 'All') {
      if (previousIndexes.includes('All')) {
        newIndexes = [];
      } else {
        newIndexes = [...castingSearchIndexes];
      }
    } else {
      newIndexes =
        previousIndexes.indexOf(currentIndex) > -1
          ? previousIndexes.filter(t => t !== currentIndex)
          : previousIndexes.concat(currentIndex);
    }

    filter.castingSearchIndexes = newIndexes;
    filter.active = true;
    if (!grid) {
      this.setState({ filterModel: filter });
    }
    return this.filterCastings(filterModel);
  };

  print = (options = {}) => {
    const { selection, selectAll } = this.state;
    const { grid } = this.props;
    const projects = new Set();
    const castings = new Set();
    Object.keys(selection).forEach(key => {
      castings.add(key);
      projects.add(selection[key]);
    });
    let fileName = grid ? grid.name : 'Casting Search Results';
    if (options.saveAs) {
      fileName = options.saveAs;
    }
    this.performDownload(
      `v2/print-report`,
      fileName,
      {
        options,
        all: selectAll,
        projects: Array.from(projects),
        castings: Array.from(castings),
      },
      [{ printType: grid ? 'casting-grid' : 'castingSearchResults' }]
    );
  };

  // eslint-disable-next-line no-undef
  collapseEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: true });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isPrintManagerCollapsed: true });
        break;
      case 'grid':
        this.setState({ isGridManagerCollapsed: true });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-undef
  expandEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: false });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: false, isPrintManagerCollapsed: true, isGridManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isListManagerCollapsed: true, isPrintManagerCollapsed: false, isGridManagerCollapsed: true });
        break;
      case 'grid':
        this.setState({ isListManagerCollapsed: true, isPrintManagerCollapsed: true, isGridManagerCollapsed: false });
        break;
      default:
        break;
    }
  };

  getSelectedProjects() {
    const { selection, selectAll, castings } = this.state;
    const projects = castings
      .filter(casting => {
        return (selectAll && !selection[casting._id]) || (!selectAll && selection[casting._id]);
      })
      .map(casting => {
        return casting.projectId._id;
      });
    return projects;
  }
  // eslint-disable-next-line no-undef
  createGrid = async (title, printOptions) => {
    const { dispatch, desk } = this.props;
    const { filterModel } = this.state;
    const filterObject = (
      await dispatch(
        createCastingGrid({
          castingFilter: filterModel,
          name: title,
          printOptions: printOptions,
          deskId: desk.current._id,
        })
      )
    ).body;

    this.show(`${filterObject.name} created`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
    window.open(`/grid/casting_grid/${filterObject._id}`, filterObject._id);
  };
  // eslint-disable-next-line no-undef
  setIsDownloading = (url, isDownloading) => {
    this.setState({ isPrinting: isDownloading });
  };

  performDownload(url, fileName, options, query) {
    const { user, desk } = this.props;
    if (this.state.isPrinting) {
      return;
    }

    downloadFile(url, fileName, user, desk.current, this.setIsDownloading, options, query);
  }
  // eslint-disable-next-line no-undef
  onSelectAllChanged = () => {
    const { selectAll } = this.state;
    this.setState({ selectAll: !selectAll, selection: {} });
  };
  // eslint-disable-next-line no-undef
  onSelectedChanged = casting => {
    const { selection } = this.state;
    const key = Object.keys(casting)[0];
    if (selection[key]) {
      delete selection[key];
    } else {
      selection[key] = casting[key];
    }
    this.setState({ selection });
  };
  async searchCompany(value, type) {
    const role = groupRoles[type] || groupRoles.AnyRole;
    const { data } = await getGroups(value, { role });
    return data;
  }
}

const withStyles = styled({
  body: {
    margin: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  tableColumn: {
    flex: 0.815,
  },
  table: {
    display: 'flex',
    width: '80vw',
    height: '90%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerStyles: {
    display: 'flex',
    margin: 'auto',
    width: '100%',
  },
  filterColumn: {
    display: 'flex',
    flex: 0.185,
    flexDirection: 'column',
  },
  typeSelectionColumn: {
    display: 'flex',
    flex: 0.185,
    flexDirection: 'column',
    marginBottom: '10px',
  },
  searchInputColumn: {
    display: 'flex',
    flex: 0.815,
    flexDirection: 'column',
    marginBottom: '0px',
  },
  listColumn: {
    minWidth: '300px',
  },
  tableColumnCollapsed: {
    display: 'flex',
    flex: 0.99,
    flexDirection: 'column',
  },
  filterColumnCollapsed: {
    width: 25,
    flexDirection: 'column',
  },
  countLabel: { marginLeft: 8, color: '#666', fontSize: '10pt' },
});

const mapStateToProps = state => {
  return { user: state.user, desk: state.desk };
};

export default connect(mapStateToProps)(withStyles(SearchCastings));
