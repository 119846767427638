import React from 'react';
import LaunchDarklyWrapper from '../../../../../components/LDWrapper/LaunchDarklyWrapper';
import AlgoliaSearchCastingIndex from './algolia-search-casting-index';
import SearchCastingCurrent from './search-casting-current';

function index(props) {
  return (
    <LaunchDarklyWrapper
      render={flags => {
        return (
          <>
            {flags?.algoliaSearchCastingFlag ? (
              <AlgoliaSearchCastingIndex {...props} featureFlags={flags} />
            ) : (
              <SearchCastingCurrent {...props} />
            )}
          </>
        );
      }}
    />
  );
}

export default index;
