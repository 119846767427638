export const defaultStatuses = [
  'Open',
  'Attached',
  'Choices',
  'Offer',
  'Negotiation',
  'In Discussions',
  'Interested In',
  'Set',
  'On Hiatus',
];

const tvStatuses = ['Test Offer', 'Test Offer Coming'];

export const statusesByProjectType = {
  Feature: defaultStatuses,
  Television: defaultStatuses.concat(tvStatuses),
  Unscripted: defaultStatuses.concat(['Submitted']),
  Theatre: defaultStatuses,
  'Fairs and Festivals': defaultStatuses,
};
