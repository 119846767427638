const { REACT_APP_ENABLE_INTERCOM, REACT_APP_INTERCOM_APP_ID, NODE_ENV } = process.env;

const INTERCOM_ENABLED = REACT_APP_ENABLE_INTERCOM?.toString() === 'true' && NODE_ENV?.toString() !== 'test';
const INTERCOM_APP_ID = REACT_APP_INTERCOM_APP_ID?.toString();

export const loadIntercom = (firstName, lastName, email) => {
  INTERCOM_ENABLED &&
    email &&
    window.Intercom &&
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      name: `${firstName} ${lastName}`,
      created_at: Date.now(),
      email,
    });
};
