import React, { PureComponent } from 'react';
import { styled } from 'react-free-style';
import { ProjectBadges } from '../../../../../components/filter-badges/filter-project-badges';
import ActionButtons from '../../../../../components/action-buttons/action-buttons';
import SortableColumnHeader from '../../../../../components/sortable-column-header/sortable-column-header';
import Project from './project';

export class ProjectsList extends PureComponent {
  // eslint-disable-next-line no-undef
  scrollListener = () => {
    const { maxCount, resultCount, projects, onFetchMore } = this.props;

    if (onFetchMore && projects && projects.length < maxCount && projects.length < resultCount) {
      const scrollHeight = document.scrollingElement.scrollHeight;
      const scrollTop = document.scrollingElement.scrollTop;
      const clientHeight = document.scrollingElement.clientHeight;
      const triggerPercentage = 0.2;

      if (scrollHeight - (scrollTop + clientHeight) < scrollHeight * triggerPercentage) {
        onFetchMore();
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('scroll', this.scrollListener);
    }
  }

  // eslint-disable-next-line no-undef
  onSelectedChanged = project => {
    const { onSelectedChanged } = this.props;
    onSelectedChanged && onSelectedChanged(project._id);
    this.forceUpdate();
  };

  // eslint-disable-next-line no-undef
  selectAll = selected => {
    const { onSelectedChangedAll } = this.props;
    onSelectedChangedAll && onSelectedChangedAll(selected);
    this.forceUpdate();
  };

  render() {
    const {
      styles = {},
      buttons,
      selectAll,
      selection,
      onSortChanged,
      columns,
      sortColumn,
      sortDirection,
      projects,
      maxCount,
      resultCount,
    } = this.props;
    const projectsLength = projects && projects.length;
    return (
      <React.Fragment>
        <ActionButtons buttons={buttons} />
        <ProjectBadges resetFilters={this.props.resetFilters} badges={this.props.filterBadges} />
        <div className={styles.table}>
          <table
            className={`table table-light table-bordered table-hover table-sm table-responsive ${styles.infiniteGrid}`}
          >
            <thead>
              <tr>
                <th width="25" style={{ textAlign: 'left' }}>
                  <input
                    type="checkbox"
                    style={{ height: 14, width: 14, position: 'relative', top: 3 }}
                    onChange={() => {
                      this.selectAll(!selectAll);
                    }}
                    checked={selectAll}
                  />
                </th>
                {columns &&
                  columns.map((column, index) => {
                    return (
                      <th key={index} scope="col" width={column.width}>
                        <SortableColumnHeader
                          text={column.name}
                          id={`column-header-${index}`}
                          direction={sortColumn === column.name && sortDirection}
                          onClick={direction => {
                            onSortChanged && onSortChanged(column.name, direction);
                          }}
                        />
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {projects &&
                projects.map((project, index) => (
                  <Project
                    project={project}
                    key={index}
                    selected={selectAll ? !selection.has(project._id) : selection && selection.has(project._id)}
                    columns={columns}
                    onSelectedChanged={this.onSelectedChanged}
                  />
                ))}
            </tbody>
          </table>
          {projectsLength < maxCount && this.renderPageCount(projectsLength, resultCount)}
          {projectsLength >= maxCount && (
            <div id="limit-reached-message" style={{ textAlign: 'center' }}>
              <p style={{ display: 'inline-block', fontWeight: 600 }}>
                Showing the first {maxCount} results of {resultCount} matching your search.
              </p>
              <br />
              <p style={{ display: 'inline-block', fontWeight: 600 }}>Please narrow your search to show all results.</p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderPageCount(currentProjectsAmount, totalProjects) {
    return (
      <div style={{ float: 'right' }}>
        <span>
          {currentProjectsAmount} / {totalProjects}
        </span>
      </div>
    );
  }
}

const withStyles = styled({
  infiniteGrid: {
    fontWeight: 'lighter',
    fontSize: '12px',
  },
  tr: {
    '&:hover': {
      '>td': {
        backgroundColor: '#F9F9F9',
        cursor: 'pointer',
      },
    },
  },
  sort: {
    color: 'silver',
    marginLeft: 5,
  },
  'caret-up': {
    color: 'black',
    marginLeft: 5,
  },
  'caret-down': {
    color: 'black',
    marginLeft: 5,
  },
  sortableColumnName: {
    cursor: 'pointer',
  },
  count: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingRight: '5px',
    paddingTop: '10px',
    marginTop: '-15px',
    backgroundColor: '#F5F5F5',
  },
});

export default withStyles(ProjectsList);
