import React from 'react';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import BeatLoader from 'react-spinners/BeatLoader';
const ArchiveModal = ({
  isArchiveModal,
  resultCount,
  isArchiveButtonEnabled,
  getSelectedArchiveProjectsCount,
  handleProjectArchiving,
  closeModal,
  isLoading,
  isArchive,
}) => {
  return (
    <Modal
      contentLabel="Archive Projects"
      onRequestClose={closeModal}
      isOpen={isArchiveModal}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
          fontFamily: 'aktiv-grotesk',
          height: '250px',
          width: '700px',
          borderRadius: '10px',
          color: '#4a4a4a',
          padding: '10px 20px',
          border: '1px solid #141414',
        },
      }}
    >
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center border-bottom w-100 pb-2">
          <div></div>
          <div style={{ fontSize: '20px' }}>
            {!isArchiveButtonEnabled()?.isAllActive ? 'Unarchive' : 'Archive'} Selected
          </div>
          <ImCross aria-label="close" style={{ cursor: 'pointer' }} onClick={!isLoading ? closeModal : null} />
        </div>
        <div className="py-3">
          <div>
            Are you sure you want to {!isArchiveButtonEnabled()?.isAllActive ? 'Unarchive' : 'Archive'}{' '}
            {getSelectedArchiveProjectsCount()} of {resultCount} items?
          </div>
          <div className="d-flex flex-row-reverse pt-3 mt-4">
            <Button className="border border-dark ml-3" outline onClick={closeModal} disabled={isArchive}>
              <div
                className="d-flex align-items-center"
                style={{
                  fontSize: '0.85em',
                  fontWeight: 'bold',
                  lineHeight: 1,
                  textTransform: 'uppercase',
                }}
              >
                <div className="py-1">cancel</div>
              </div>
            </Button>
            <Button
              className="border border-dark"
              outline
              bsStyle="danger"
              onClick={() => handleProjectArchiving(isArchiveButtonEnabled()?.isAllActive)}
              disabled={isArchive}
            >
              <div
                className="d-flex align-items-center"
                style={{
                  fontSize: '0.85em',
                  fontWeight: 'bold',
                  lineHeight: 1,
                  textTransform: 'uppercase',
                }}
              >
                <div className="py-1">{!isArchiveButtonEnabled()?.isAllActive ? 'Unarchive' : 'Archive'}</div>
              </div>
            </Button>
          </div>

          <div className="d-flex justify-content-center py-2">
            <BeatLoader
              color={'#141414'}
              loading={isArchive}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: '25px' }}>
        <p>
          <span>&#9888; </span>
          Note: Please refresh webpage to see updated Project Status after Bulk Archive/Unarchive
        </p>
      </div>
    </Modal>
  );
};

export default ArchiveModal;
