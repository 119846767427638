import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { getCompaniesIndex } from '../../support/algolia/get-index';
import { searchClient } from '../../support/algolia/algolia-search-client';
import { groupRoles } from '../../api/groups';

const defaultAlgoliaFetch = async (searchQuery, pageNumber, role) => {
  try{
    const index = getCompaniesIndex(true);
    const filters = `(roles:${extractRoleSubstring(role)})`;
    const result = await searchClient(index, searchQuery, {
      filters,
      hitsPerPage: 500,
      page: pageNumber,
    });
    const newOptions = result.hits.map(hit => ({
      value: hit,
      label: hit.name,
    }));
    return { newOptions, hasMore: result.page < result.nbPages - 1};
  } catch(e){
    console.error(e);
    throw e;
  }
};

const InfiniteScrollDropdown = ({ label = '', onSelectCompany, algoliaFetchOptions = defaultAlgoliaFetch }) => {
  const role = groupRoles[label] || groupRoles.AnyRole;
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = useCallback(async (searchQuery, pageNumber, isDefault = false) => {
    setIsLoading(true);
    try {
      const { newOptions, hasMore } = await algoliaFetchOptions(searchQuery, pageNumber, role);
      console.log(newOptions, hasMore);
      isDefault
        ? setDefaultOptions(newOptions)
        : setOptions(prevOptions => (pageNumber === 0 ? newOptions : [...prevOptions, ...newOptions]));
      setHasMore(hasMore);
    } catch (error) {
      console.error('Algolia search error:', error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 100), []);

  useEffect(() => {
    fetchOptions('', 0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValue) {
      setPage(0);
      setOptions([]);
      setHasMore(true);
      debouncedFetchOptions(inputValue, 0);
    } else {
      setPage(0);
      setOptions(defaultOptions);
      setHasMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, debouncedFetchOptions]);

  const handleInputChange = query => {
    setInputValue(query);
  };

  const handleMenuScrollToBottom = () => {
    if (hasMore && !isLoading) {
      fetchOptions(inputValue, page + 1);
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleFocus = () => {
    if (!inputValue) setOptions(defaultOptions);
  };

  const handleBlur = () => {
    setPage(0);
    setOptions([]);
    setHasMore(true);
  };

  const handleClear = () => {
    setInputValue('');
    setPage(0);
    setOptions([]);
    setHasMore(true);
    setOptions(defaultOptions);
  };

  const NoOptionsMessage = ({ innerProps }) => (
    <div className="px-2 py-4" {...innerProps}>
      No results found
    </div>
  );

  const handleKeyDown = event => {
    if (event.key === ' ' && !event.target.value) {
      event.preventDefault();
    } else if (event.key === 'Backspace' && !event.target.value) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Select
        options={options.length ? options : defaultOptions}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onFocus={handleFocus}
        placeholder={label}
        isLoading={isLoading}
        styles={customStyles}
        onChange={selectedItems => {
          if (selectedItems && selectedItems.length > 0) {
            onSelectCompany(selectedItems[selectedItems.length - 1]?.value);
          } else {
            onSelectCompany([]);
          }
        }}
        isMulti={true}
        controlShouldRenderValue={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage,
        }}
        isClearable={false}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onClear={handleClear}
      />
    </div>
  );
};

export default React.memo(InfiniteScrollDropdown);

const extractRoleSubstring = str => {
  const dotIndex = str.lastIndexOf('.');
  if (dotIndex !== -1) {
    return str.substring(dotIndex + 1);
  } else {
    return str;
  }
};

const debounce = (func, delay) => {
  let debounceTimer;
  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const customStyles = {
  control: base => ({
    ...base,
    borderRadius: 0,
    border: '1px solid #EBEBEB',
    fontSize: '13px',
    boxShadow: 'none',
    cursor: 'text',
    '&:hover': {
      border: 'none',
    },
  }),
  menu: base => ({
    ...base,
    backgroundColor: '#FFF',
    border: '1px solid #EBEBEB',
    boxShadow: 'none',
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 0,
  }),
  menuList: base => ({
    ...base,
    fontSize: '13px',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#f5f5f5' : '#FFF',
    color: state.isFocused ? '#000' : '#333',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
  }),
};
