import React from 'react';
import Select from 'react-select';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const DropdownOptions = ({ label, isSelected, isDisabled, onChange }) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      onClick={onChange}
    >
      <input type="checkbox" value={label} checked={isSelected} readOnly style={{ marginRight: '5px' }} disabled={isDisabled} />
      <label style={{ marginBottom: 0 }}>{label}</label>
    </div>
  );
};

// Custom Dropdown Indicator
const DropdownIndicator = props => {
  const { selectProps } = props;
  const isOpen = selectProps.menuIsOpen;
  return (
    <div style={{ padding: '0 8px', cursor: 'pointer', fontSize: '11px' }}>
      {isOpen ? <FaChevronUp /> : <FaChevronDown />}
    </div>
  );
};

export const Multiselect = ({ title, options, onSelect, selectedOptions, disabledOptions = [], loading = false }) => {
  const formattedOptions = options.map(option => ({
    value: option,
    label: (
      <DropdownOptions
        label={option}
        isSelected={selectedOptions.includes(option)}
        isDisabled={disabledOptions.includes(option)}
      />
    ),
  }));
  const isDisabled = option => {
    return disabledOptions.includes(option);
  };

  // Handle selection changes
  const handleChange = selected => {
    const selectedValues = !isDisabled(selected) && selected ? selected.map(item => item.value) : [];
    onSelect(selectedValues.toString());
  };

  if (loading) return <Select isLoading options={[]} isDisabled />;

  return (
    <React.Fragment>
      <Select
        placeholder={title}
        isMulti
        options={formattedOptions}
        onChange={handleChange}
        value={null}
        isSearchable={false}
        isClearable={false}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        components={{
          DropdownIndicator,
        }}
        styles={customStyles}
      />
      {selectedOptions.map(selectedOption => (
        <div
          key={selectedOption}
          style={{
            marginRight: '15px',
            display: 'inline-block',
          }}
          onClick={() => onSelect(selectedOption)}
        >
          <DropdownOptions label={selectedOption} isSelected isDisabled={disabledOptions.includes(selectedOption)} />
        </div>
      ))}
    </React.Fragment>
  );
};

const customStyles = {
  option: styles => ({
    ...styles,
    fontSize: '13px',
    padding: '3px',
    cursor: 'pointer',
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: 'rgba(36, 149, 212, 0.15)',
    },
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
    boxShadow: 'none',
  }),
  menuList: provided => ({
    ...provided,
    border: '1px solid #EBEBEB',
    boxShadow: '0 0 5px #EBEBEB',
    maxHeight: '550px',
    fontSize: '13px',
    padding: '5px 10px',
    overflowY: 'scroll',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '0.7em',
    fontWeight: 300,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
};
